import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Select } from "theme-ui";
import DoubleChevron from "assets/double-chevron.svg";

export const TableWrapper = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    width: 100%;
  }
`;

export const PaginationText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.greys[500]};
  `}
`;

const SelectComponent = styled.div`
  ${({ theme }) => css`
    position: relative;

    select {
      border: 1px solid ${theme.colors.greys[600]};
      font-weight: inherit;
      height: 31px;
      min-width: 150px;
      width: 150px;
      color: ${theme.colors.greys[600]};
      border-radius: 4px;
      background-color: ${theme.colors.white};
      margin: 9px 0;
      padding: 6px 30px 6px 12px;
    }
    svg {
      display: none;
    }
    img {
      width: 12px;
      height: 12px;
      position: absolute;
      left: 130px;
      top: 18px;
    }
  `};
`;

const Entries = ({ pageSize, onChange }) => {
  return (
    <SelectComponent>
      <Select value={pageSize} onChange={onChange}>
        {[5, 10, 15, 20, 25].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize} Entries
          </option>
        ))}
      </Select>
      <img src={DoubleChevron} alt="" />
    </SelectComponent>
  );
};

export const NoData = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.white};
    color: ${theme.colors.primary};
    padding: 15px;
    text-align: center;
    font-family: ${theme.fonts.body};
  `}
`;

export const Status = styled.div`
  ${({ theme }) => css`
    border-radius: 4px;
    text-align: left;
    font-family: ${theme.fonts.body};
    font-weight: ${theme.fontWeights.semiBold};
    font-size: 12px;
    border-radius: 2px;
    background: transparent;

    button {
      border: 1px solid transparent;
      font-size: 12px;
      border-radius: 2px;
      width: 90px;
      height: 19px;
      outline: none;
      cursor: pointer;
    }

    span {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .in-progress {
      color: ${theme.colors.purple};
      .dot {
        background-color: ${theme.colors.purple};
      }
    }

    .pending {
      color: ${theme.colors.orange};
      .dot {
        background-color: ${theme.colors.orange};
      }
    }
    .completed {
      color: ${theme.colors.green};
      .dot {
        background-color: ${theme.colors.green};
      }
    }

    .active {
      color: ${theme.colors.blues[200]};
      .dot {
        background-color: ${theme.colors.blues[200]};
      }
    }
    .inactive {
      color: ${theme.colors.greys[400]};
      .dot {
        background-color: ${theme.colors.greys[400]};
      }
    }

    .error {
      color: ${theme.colors.notifications.error};
      .dot {
        background-color: ${theme.colors.notifications.error};
      }
    }
  `};
`;

export const Placeholder = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.highlight};
    color: ${theme.colors.primary};
    padding: 15px;
    text-align: center;
    min-height: 170px;
  `}
`;

export const PaginationWrapper = styled.div`
  ${({ theme }) => css`
    .rc-pagination {
      display: flex;
      width: 100%;
      padding: 10px 0;
    }

    .rc-pagination-total-text {
      color: ${theme.colors.greys[500]};
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      list-style: none;
      padding: 0;
      margin: 0 auto 0 0;
    }

    .rc-pagination-item {
      border: none;
      background-color: transparent;
      font-weight: bold;
      a {
        text-decoration: none;
        color: ${theme.colors.greys[500]};
      }
    }
    .rc-pagination-prev,
    .rc-pagination-next {
      button {
        border-radius: 50%;
      }
    }

    .rc-pagination-item-active a {
      color: ${theme.colors.blues[200]};
    }
  `}
`;

export default Entries;
