import React from "react";
import TwigaRouter from "./routes";
import "react-phone-number-input/style.css";

function App() {
  return (
    <div className="twiga__dashboard">
      <TwigaRouter />
    </div>
  );
}

export default App;
