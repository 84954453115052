import { get } from "lodash-es";

export const formatError = error => {
  let message = get(error, "response.statusText");
  const status = get(error, "response.status", 500);
  let description = get(error.message);

  if (status === 400) {
    message = "Bad Request";
    description =
      "The server could not understand the request due to invalid syntax";
  } else if (status === 401) {
    message = "Unauthorized";
    description = "Unauthorized request. Please login to continue";
  } else if (status === 403) {
    message = "Permission Denied";
    description = "You do not have the required permission to access this item";
  } else if (status === 422) {
    message = "Validation Error";
    description = "Please enter correct input and try again";
  } else if (status === 500) {
    message = "Internal Server Error";
    description = "An unexpected error occured. Refresh page and try again";
  } else if (status === 409) {
    message = "Duplicate Record";
    description = "A similar record already exists in the system";
  } else if (status === 429) {
    message = "Too Many Requests";
    description = "Too may requests. Please try after a few minutes";
  }

  if (!description) {
    description = "Unable to complete your request. Try refreshing the page";
  }

  const validationErrors = get(error, "response.data.errors", []);

  return {
    message,
    description,
    status,
    type: "error",
    validationErrors
  };
};
