import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { version } from "../../package.json";
import * as Sentry from "@sentry/react";

const { REACT_APP_BASE_URL: API_BASE_URL } = process.env;

const PRODUCTION_HOST_NAME = "dashboard.twiga.tech";

export const isProductionInstance =
  typeof window !== "undefined" &&
  window.location.host === PRODUCTION_HOST_NAME;

export const GRAPH_CDN_APP_URL = "https://dms-v2.api.twiga.tech/graphql";

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem("twiga_dashboard_token"));

  return {
    headers: {
      ...headers,
      "apollographql-client-name": "Dashboard_V3",
      "apollographql-client-version": version,
      authorization: token ? `Bearer ${token.access_token}` : null
    }
  };
});

const handleError = onError(({ networkError, graphQLErrors }) => {
  if (networkError && networkError.statusCode === 401) {
    console.log(`[Network error]: ${networkError}`);
    localStorage.setItem("twiga_dashboard_user", null);
    localStorage.setItem("twiga_dashboard_token", null);
    window.location.href = "/auth/login";
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      Sentry.captureException(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
});

const httpLink = createHttpLink({
  uri: isProductionInstance ? GRAPH_CDN_APP_URL : `${API_BASE_URL}/graphql`
});

const client = new ApolloClient({
  uri: isProductionInstance ? GRAPH_CDN_APP_URL : `${API_BASE_URL}/graphql`,
  cache: new InMemoryCache(),
  link: from([authLink, handleError, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore"
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    },
    mutate: {
      errorPolicy: "all"
    }
  }
});

export default client;
