import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { customStyles } from "components";
import { useCountriesQuery, useRoutes_V2Query } from "generated/graphql";
import { UseRegionFilter } from "hooks";
import { get } from "lodash";
import { pseudoConstraints } from "pages/admin/pseudo-routes/constants";
import React, { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Alert, FormLayout } from "styles";
import { Box, Button, Grid } from "theme-ui";
import * as yup from "yup";

const Form = styled.form`
  width: 600px;
`;

type FormContentProps = {
  showPadding?: boolean;
};

const FormContent = styled.div<FormContentProps>`
  padding: ${props => (props.showPadding ? "0px 40px" : "0px")};
`;

FormContent.defaultProps = {
  showPadding: true
};

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  constraints: yup.array().nullable().required("Constraints is required"),
  routes: yup.array().nullable().required("Routes is required")
});

interface PseudoRouteFormProps {
  defaultValues?: any;
  onSubmit: (data: any) => void;
  loading?: boolean;
  httpError?: any;
}

const PseudoRouteForm: FC<PseudoRouteFormProps> = ({
  defaultValues,
  onSubmit,
  httpError,
  loading
}) => {
  const [country, setCountry] = React.useState<any>(null);
  const [region, setRegion] = React.useState<any>(null);
  const [depot, setDepot] = React.useState<any>(null);

  const {
    control,
    formState: { errors },
    watch,
    reset,
    handleSubmit
  } = useForm({
    defaultValues: defaultValues
      ? defaultValues
      : {
          name: "",
          constraints: [],
          constraint_weight: { label: "Weight", value: "weight" },
          weight_constraint_value: "",
          max_shops_constraint_value: "",
          max_shops_constraint: { label: "Max Shops", value: "max_shops" }
        },
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const constraints = watch("constraints");

  const { loading: loadingCountries, data: countryData } = useCountriesQuery();

  const countriesData = get(countryData, "countries", []);
  const countries = countriesData?.map(
    ({ country_id: value, iso_alpha2_name: label, iso_alpha2_code }: any) => ({
      value,
      label,
      iso_alpha2_code
    })
  );

  const {
    loadingRegions,
    onRegionChange,
    filteredDepots,
    regionOptions
  } = UseRegionFilter(country ? [country.value] : undefined);

  const constraintExists = (constraint_key: any) =>
    constraints.some((constraint: any) => constraint.value === constraint_key);

  const { data, loading: routesLoading } = useRoutes_V2Query({
    variables: {
      filters: {
        depot_id: depot?.value
      }
    } as any
  });

  const routesOptions = get(data, "routes_v2.edges", []).map(
    ({ node }: any) => ({
      ...node,
      label: node.route_name,
      value: node.route_id
    })
  );

  const history = useHistory();

  return (
    <FormLayout>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>New Pseudo Route</h1>
        <FormContent>
          {httpError?.message && (
            <Alert className="alert danger">
              <strong>{httpError?.message}</strong>
              {httpError?.description && <div>{httpError?.description}</div>}
            </Alert>
          )}
          <Controller
            name="name"
            control={control}
            render={field => (
              <>
                <label>Name</label>
                <input
                  placeholder="Enter pseudo route name"
                  {...field}
                  name="name"
                />
                {errors.name && <span>{errors.name.message}</span>}
              </>
            )}
          />
          <label>Constraints</label>
          <Controller
            control={control}
            name="constraints"
            render={({ value, ref, onChange }) => (
              <Select
                options={pseudoConstraints}
                styles={customStyles}
                cacheOptions
                value={value}
                onChange={onChange}
                ref={ref}
                placeholder="- Select Constraints -"
                name="constraints"
                isMulti
                isClearable={false}
              />
            )}
          />
          {errors.constraints && (
            <span>{(errors.constraints as any).message}</span>
          )}
          {constraintExists("weight") && (
            <Grid gap={20} columns={[2, "1fr 1fr"]}>
              <div>
                <label>Select Constraint (Weight)</label>
                <Controller
                  control={control}
                  name="constraint_weight"
                  render={field => (
                    <Select
                      options={pseudoConstraints}
                      isDisabled
                      styles={customStyles}
                      cacheOptions
                      {...field}
                      placeholder="- Select Constraint(Weight) -"
                      isClearable={false}
                    />
                  )}
                />
                {errors.constraint_weight && (
                  <span>{(errors.constraint_weight as any).message}</span>
                )}
              </div>
              <Controller
                name="weight_constraint_value"
                control={control}
                render={field => (
                  <div>
                    <label>Weight Constraint Value</label>
                    <input
                      placeholder="Constraint Value"
                      {...field}
                      type="number"
                    />
                    {errors.weight_constraint_value && (
                      <span>{errors.weight_constraint_value.message}</span>
                    )}
                  </div>
                )}
              />
            </Grid>
          )}
          {constraintExists("max_shops") && (
            <Grid gap={20} columns={[2, "1fr 1fr"]}>
              <div>
                <label>Select Constraint (Max Shops)</label>
                <Controller
                  control={control}
                  name="max_shops_constraint"
                  render={field => (
                    <Select
                      isDisabled
                      options={pseudoConstraints}
                      styles={customStyles}
                      cacheOptions
                      {...field}
                      placeholder="- Select Max Shops -"
                      isClearable={false}
                    />
                  )}
                />
                {errors.max_shops_constraint && (
                  <span>{(errors.max_shops_constraint as any).message}</span>
                )}
              </div>
              <Controller
                name="max_shops_constraint_value"
                control={control}
                render={field => (
                  <div>
                    <label>Max Shops Constraint Value</label>
                    <input
                      placeholder="Constraint Value"
                      {...field}
                      type="number"
                    />
                    {errors.max_shops_constraint_value && (
                      <span>{errors.max_shops_constraint_value.message}</span>
                    )}
                  </div>
                )}
              />
            </Grid>
          )}
          <label>Select Country</label>
          <Select
            options={countries}
            cacheOptions
            styles={customStyles}
            isClearable
            isLoading={loadingCountries}
            value={country}
            defaultValue={country}
            placeholder="Select Country"
            onChange={value => {
              setCountry(value);
            }}
          />
          <label>Select Region</label>
          <Select
            options={regionOptions}
            cacheOptions
            styles={customStyles}
            isDisabled={loadingRegions}
            placeholder="Region"
            value={region}
            onChange={value => {
              onRegionChange(value);
              setRegion(value);
            }}
          />
          <label>Select Depot</label>
          <Select
            options={filteredDepots}
            cacheOptions
            styles={customStyles}
            placeholder="Depot"
            value={depot}
            onChange={value => {
              setDepot(value);
            }}
          />
          <label>Select Routes</label>

          <Controller
            control={control}
            name="routes"
            render={field => (
              <Select
                options={routesOptions}
                cacheOptions
                isMulti
                isLoading={routesLoading}
                isDisabled={!depot}
                styles={customStyles}
                placeholder="Routes"
                {...field}
              />
            )}
          />
          {errors.constraints && (
            <span>{(errors.constraints as any).message}</span>
          )}
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              type="button"
              variant="cancel"
              onClick={() => {
                reset();
                history.push("/admin/pseudo-routes");
              }}
            >
              Cancel
            </Button>
            <Button disabled={loading} variant="secondary" type="submit">
              Save Psuedo Route
            </Button>
          </Box>
        </FormContent>
      </Form>
    </FormLayout>
  );
};

export default PseudoRouteForm;
